<template>
  <div class="bodycont" id='bodycont'>

    <comHeader />
  <div class="aboutbanner"></div>
    <div class="main" >
      <navTitle :navData='navData'/>
      <div v-if="newId==1" class="newttbox">
        <div class="news_titless">递进式执法 实现现场执法简易化</div>
        <div class="news_pt">
              为进一步增强群众的交通安全意识，提升钟祥市交通执法管理能力，扎实推进“一盔一带”安全守护行动，全面提高电动车、摩托车、三轮车驾乘人员安全头盔佩戴率，尤其是三轮车主的头盔佩戴率，降低交通事故死亡风险。
        </div>
        <div class="news_pt">
             为此，我公司为钟祥市开发一套交警执法小程序，将交通警察、村干部、综治干部纳入到交通执法一体化治理体系中。通过人工智能，实现现场执法简易化，利用协同作战，提升执法效果，降低钟祥市交通违法情况。
        </div>
        <div class="nwss-imag">
           <img src="../../assets/images/news/news-detail1.png" alt="" />
        </div>
         <div class="nwss-imag">
           <img src="../../assets/images/news/news-detail2.png" alt="" />
        </div>
        </div>
        <div v-if="newId==2" class="newttbox">
           <div class="news_titless">全数据汇聚、全要素管控、全流程闭环 - 治安要素管控</div>
        <div class="news_pt">
             建立和完善矛盾纠纷多元化解机制，对于保障群众合法权益、促进社会公平正义具有重要意义。近年来，伴随着我国社会经济快速发展、经济体制变革不断深化、社会利益格局不断调整，社会矛盾纠纷处于频发时期，且呈现出纠纷主体多元化、利益诉求复杂化、纠纷类型多样化等特点。
        </div>
        <div class="news_pt">
             科学有效地化解社会矛盾，是推动社会和谐，促进地区经济社会持续健康发展的重要方法。为此，钟祥市市委平安办制订了“找窟窿、堵窟窿、防透风”(以下简称“找堵防”)工作机制，为进一步健全完善党政领导、政法牵头、部门主管、基层主责、社会参与的基层社会综合治理体系，建立矛盾纠纷风险隐患化解党政大闭环，推动市域治理现代化在最小单元走深走实，制定了相应的组织体系和工作机制。
        </div>
         <div class="news_pt">
             为促进“找堵防”工作机制的落实和高效运行，应紧密结合工作机制的核心内容，运用多媒体、互联网、智能终端、大数据等现代信息技术手段，建设一套简单实用、区域共享、跨部门的“找堵防”管控系统，为全市矛盾纠纷化解提供技术保障。
        </div>
        </div>
       <div v-if="newId==3" class="newttbox">
            <div class="news_titless">新春开工！新征程，再出发！</div>
        <div class="news_pt">
         2024年2月18日，全体员工一起开总结大会，主要内容：2023年总结，分析现状；2024年展望，规划未来发展及公司管理、财务、技术培训。 </div>
          <div class="nwss-imag mt30">
           <img src="../../assets/images/news/news-detail3.png" alt="" />
        </div>
        <div class="news_pt">
            2024年2月19日公司只要产品培训，主要产品内容：行政执法监督和基本操作培训、一键视频报警培训、钟祥治安要素培训、钟祥综治考评培训、钟祥交警
        </div>
         <div class="nwss-imag mt30">
           <img src="../../assets/images/news/news-detail4.png" alt="" />
        </div>
      </div>
    </div>
    <bNav />
  </div>

</template>

<script>
  import comHeader from '@/components/header.vue'
  import bNav from '@/components/bottomNav.vue'
  import navTitle from '@/components/navtitle.vue'
  import api from '@/config/api'
  import {
    getRequst,postRequest
  } from '@/api/index'
  export default {
    components: {
      comHeader,
      bNav,
      navTitle
    },
    watch: {
     
    },
    data() {
      return {
        navData:[ ],
        newId:'',
        dataInfo:{},
      }
    },

    created() {
      this.newId = this.$route.params.id;
      console.log(this.$route,555);
      this.navData=[
          // {
          //   name:this.$route.query.name,
          //   url:this.$route.query.url,
          // },
           {
            name:'新闻',
            url:'/notice',
          },
          {
            name:'详情页',
            url:'newsdetails',
          },
      ]
    },
    mounted() {
      //this.getInfo()
    },
    methods: {
      //获取列表
      getInfo() {
        let data = {
          newId: this.newId,
        }
        postRequest(api.newsDetails, data).then(res => {
           this.dataInfo = res.data
        })

      },
      //查看详情
      tourl(data) {
        this.$router.push({
          path: '/teamDetails',
          query: {},
        });
      }

    }
  }
</script>

<style lang="less" scoped>
  .cont{
    /deep/ p{
      margin-top: 20px;

    }
    /deep/ span{
     line-height: 30px;
     white-space:normal;
     width: 1180px;
    }
  }
  .title {
      font-size: 30px;
      font-weight: normal;
      color: #547d98;
      margin-bottom: 20px;
      margin-top: 20px;
      text-align: center;}
      .hur1 {
          text-align: center;
          line-height: 30px;
          margin-bottom: 20px;
      }
  .bodycont {
    // background: url('../../assets/images/homebg1.png') repeat-x;
    width: 100%;
  }
  .main{
    width:1190px;
    margin:0px auto 20px;
    min-height: 75vh;
  }
 .aboutbanner{
    width:100%;
    height:300px;
    background: url(../../assets/images/about/about.png) no-repeat center center;
    background-size: cover;
  }
  .news_titless{
    margin-top:50px;
    font-size:28px;
    text-align: center;
    margin-bottom:60px;
  }
  .news_pt{
    font-size: 16px;
    line-height: 32px;
    text-indent:2em;
    margin-bottom:30px;
  }
  .newttbox{
    padding:0 100px;
    padding-bottom:100px;
  }
  .nwss-imag{
    width:800px;
    margin:0 auto;
    margin-bottom:30px;
  }
  .nwss-imag img{
    width:100%;
    height:auto;
  }
  .mt30{
    margin-top:30px;
  }
</style>
